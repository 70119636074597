import React, { useState, Fragment, useEffect } from 'react';
// @ts-ignore
import http from '../http';
import toastr from 'toastr';
import {
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  TextField,
  Switch,
  FormControlLabel,
  withStyles
} from '@material-ui/core';
import { parseLookupKey, constructLookupKey } from './LookupKeyParser';
import './DynamicAdEnhancementForm.scss';
import EnhancementMessage, {
  Props as EnhancementMessageProps
} from './enhancement_status/EnhancementMessage';

type Source = {
  source_key: string;
  display_name: string;
};

type DynamicAdEnhancement = {
  id?: number | null;
  lookup_key: string;
  source_id: string;
  ad_format: string;
  description: string;
  title: string;
  advertiser: string;
  media_url: string;
  spotx_deal: boolean;
  flush_srt: boolean;
};

type Props = {
  adFormats: string[][];
  dynamicAdEnhancement: DynamicAdEnhancement;
  enhancementStatus?: EnhancementMessageProps | null;
  videoSources: Source[];
  bannerSources: Source[];
  srtContent?: string;
};

const SPOTX_SOURCE_ID = 'k1jJghvBi79yX1NZ2sM5fXrm';
export const RUBICON_DISPLAY_SOURCE_ID = 'HDHY4fY4bB65i2Rv4N42K1Ed';
export const RUBICON_VIDEO_SOURCE_ID = 'UiRtTsXAfjmfSDAKnR1FjWsu';

const originDsps: { [key: string]: string } = {
  '2249:': 'DV360',
  '1512:': 'MediaMath',
  other: 'Other'
};

const GreenSwitch = withStyles({
  switchBase: {
    color: '#0E1C28',
    '&$checked': {
      color: '#3de0c8'
    },
    '&$checked + $track': {
      backgroundColor: '#3de0c8'
    }
  },
  checked: {},
  track: {}
})(Switch);

const DynamicAdEnhancementForm = (props: Props) => {
  const {
    adFormats,
    dynamicAdEnhancement,
    videoSources,
    enhancementStatus,
    bannerSources,
    srtContent
  } = props;

  const {
    initalCreativeKey,
    initialDealKey,
    initialCreativeKeyPrefix
  } = parseLookupKey(
    dynamicAdEnhancement.lookup_key,
    dynamicAdEnhancement.source_id,
    dynamicAdEnhancement.spotx_deal
  );

  // TODO: useInputs hook or something
  const [sources, setSources] = useState(bannerSources);
  const [adFormat, setAdFormat] = useState(
    dynamicAdEnhancement.ad_format || adFormats[0][1]
  );
  const [sourceId, setSourceId] = useState(
    dynamicAdEnhancement.source_id || sources[0]['source_key']
  );
  const [mediaUrl, setMediaUrl] = useState(
    dynamicAdEnhancement.media_url || ''
  );

  const [title, setTitle] = useState(
    dynamicAdEnhancement.title || 'Click Here for More Information'
  );
  const [description, setDescription] = useState(
    dynamicAdEnhancement.id
      ? dynamicAdEnhancement.description
      : "Don't miss this content from our sponsor"
  );
  const [advertiser, setAdvertiser] = useState(
    dynamicAdEnhancement.advertiser || 'Sponsor'
  );
  const [creativeKey, setCreativeKey] = useState(initalCreativeKey);
  const [dealKey, setDealKey] = useState(initialDealKey);
  const [creativeKeyPrefix, setCreativeKeyPrefix] = useState(
    initialCreativeKeyPrefix
  );
  const [submitted, setSubmitted] = useState(false);
  const [creativeKeyTouched, setCreativeKeyTouched] = useState(false);
  const [flushSrt, setFlushSrt] = useState(dynamicAdEnhancement.flush_srt);
  const [enhancementProcessing, setEnhancementProcessing] = useState(false);

  const isInstream = adFormat === 'instream_video' || adFormat === 'ctv';

  useEffect(
    function resetCreativeKeyPrefix() {
      if (
        sourceId === RUBICON_DISPLAY_SOURCE_ID ||
        sourceId === RUBICON_VIDEO_SOURCE_ID
      ) {
        // if DSP is rubicon, default it to the first option if no existing prefix
        if (!creativeKeyPrefix) {
          setCreativeKeyPrefix(Object.keys(originDsps)[0]);
        }
      } else {
        setCreativeKeyPrefix('');
      }
    },
    [sourceId]
  );

  useEffect(
    function setSourcesForAdFormat() {
      if (adFormat === 'enhanced_display') {
        setSources(bannerSources);
      } else {
        setSources(videoSources);
      }
    },
    [adFormat]
  );

  const handleGenerateCaptions = () => {
    setEnhancementProcessing(true);

    http
      .post(`/video_captions`, {
        lookup_key: dynamicAdEnhancement.lookup_key,
        source_id: dynamicAdEnhancement.source_id
      })
      .then(() => {
        setFlushSrt(true);
        toastr.success('Captions are being generated');
      })
      .catch(e => {
        toastr.error(e.response.data.message);
      })
      .finally(() => {
        setEnhancementProcessing(false);
      });
  };

  const handleBurnCaptions = () => {
    setEnhancementProcessing(true);

    http
      .post(`/burn_captions`, {
        lookup_key: dynamicAdEnhancement.lookup_key,
        source_id: dynamicAdEnhancement.source_id
      })
      .then(() => {
        toastr.success('Captions are being generated');
      })
      .catch(e => {
        toastr.error(e.response.data.message);
      })
      .finally(() => {
        setEnhancementProcessing(false);
      });
  };

  const handleGenerateQrCode = () => {
    setEnhancementProcessing(true);

    http
      .post(`/qr_codes`, {
        lookup_key: dynamicAdEnhancement.lookup_key,
        source_id: dynamicAdEnhancement.source_id
      })
      .then(() => {
        toastr.success('QR Code enhancement is being generated');
      })
      .catch(e => {
        toastr.error(e.response.data.message);
      })
      .finally(() => {
        setEnhancementProcessing(false);
      });
  };

  const handleSubmit = async () => {
    setSubmitted(true);

    const request = dynamicAdEnhancement.id ? http.patch : http.post;
    const url = `/dynamic_ad_enhancements/${
      (request === http.patch && dynamicAdEnhancement.id) || ''
    }`;
    const lookupKey = constructLookupKey(
      creativeKeyPrefix,
      creativeKey,
      dealKey
    );

    try {
      await request(url, {
        dynamic_ad_enhancement: {
          ad_format: adFormat,
          source_id: sourceId,
          lookup_key: lookupKey,
          media_url: mediaUrl.trim() || null,
          title: title,
          description: description,
          advertiser: advertiser,
          spotx_deal: !!dealKey,
          flush_srt: flushSrt
        }
      });
      window.location.assign('/dynamic_ad_enhancements');
    } catch (e) {
      if (e.response && e.response.data) {
        toastr.error(e.response.data);
      } else {
        toastr.error('An error occurred');
      }
    }
  };

  return (
    <div className="pl8 pr8">
      <section className="mt2 col-12 col-md-6 col-xl-3">
        <div className="border p2">
          {enhancementStatus ? (
            <>
              <EnhancementMessage
                type={enhancementStatus.type}
                message={enhancementStatus.message}
              />
            </>
          ) : null}

          <div className="mt2">
            <p>Enter the details for the ad you want to enhance:</p>
            <div className="mt2">
              <FormControl className="dae__select">
                <InputLabel>Ad Format</InputLabel>
                <Select
                  name="ad_format"
                  inputProps={{ 'data-testid': 'ad_format' }}
                  id="ad_format"
                  value={adFormat}
                  onChange={e => {
                    setAdFormat(e.target.value as string);
                    setSourceId('');
                    if (e.target.value === 'enhanced_outstream') {
                      setMediaUrl('');
                    }
                  }}
                >
                  {adFormats.map(([displayName, value]) => (
                    <MenuItem value={value} key={displayName}>
                      {displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="mt2">
            <FormControl className="dae__select">
              <InputLabel>DSP</InputLabel>
              <Select
                name="source_id"
                id="source_id"
                inputProps={{ 'data-testid': 'dsp' }}
                value={sourceId}
                onChange={e => setSourceId(e.target.value as string)}
              >
                {sources.map(({ source_key, display_name }) => (
                  <MenuItem value={source_key} key={source_key}>
                    {display_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {(sourceId === RUBICON_DISPLAY_SOURCE_ID ||
              sourceId === RUBICON_VIDEO_SOURCE_ID) && (
              <FormControl className="ml2 dae__select">
                <InputLabel>Origin DSP</InputLabel>
                <Select
                  inputProps={{ 'data-testid': 'origin_dsp' }}
                  value={creativeKeyPrefix}
                  onChange={e => setCreativeKeyPrefix(e.target.value as string)}
                >
                  {Object.keys(originDsps).map((prefix: string) => (
                    <MenuItem value={prefix} key={prefix}>
                      {originDsps[prefix]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>

          <div className="mt2">
            {creativeKeyPrefix && (
              <p>
                {creativeKeyPrefix === 'other'
                  ? 'Please enter the Creative ID as it appears in Rubicon DSP'
                  : `Please enter the ${originDsps[creativeKeyPrefix]} Creative ID`}
              </p>
            )}
            <div
              className={
                sourceId === SPOTX_SOURCE_ID ? 'dae__lookup-key-row--spotx' : ''
              }
            >
              {creativeKeyPrefix && creativeKeyPrefix !== 'other' && (
                <TextField
                  variant="outlined"
                  value={creativeKeyPrefix}
                  className="dae__creative-id-prefix mr1"
                  disabled
                />
              )}

              <TextField
                variant="outlined"
                name="creative_key"
                inputProps={{ 'data-testid': 'creative_key' }}
                disabled={dealKey.length > 0}
                value={creativeKey}
                onChange={e => {
                  setCreativeKeyTouched(true);
                  setCreativeKey(e.target.value.trim());
                }}
                error={
                  (creativeKeyTouched || submitted) &&
                  creativeKey.trim().includes(' ')
                }
                label="Creative ID"
              />

              {sourceId === SPOTX_SOURCE_ID && (
                <Fragment>
                  <span style={{ margin: '10px' }}>OR</span>
                  <TextField
                    variant="outlined"
                    inputProps={{ 'data-testid': 'deal_key' }}
                    disabled={creativeKey.length > 0}
                    value={dealKey}
                    onChange={e => setDealKey(e.target.value)}
                    label="SpotX Deal ID"
                  />
                </Fragment>
              )}
            </div>
          </div>
        </div>

        {!isInstream && (
          <div className="mt2 col-12 col-md-12">
            <button
              data-testid="save-button"
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        )}
      </section>

      <section className="mt2 col-12 col-md-6 col-xl-3">
        <div className="border p2">
          <p>Update the ad:</p>

          {!isInstream && (
            <React.Fragment>
              <TextField
                variant="outlined"
                inputProps={{ 'data-testid': 'advertiser' }}
                type="text"
                name="advertiser"
                id="advertiser"
                value={advertiser}
                onChange={e => setAdvertiser(e.target.value)}
                onClick={e => (e.target as HTMLInputElement).select()}
                error={advertiser.trim() === ''}
                label="Ad By"
              />

              <div className="mt2">
                <TextField
                  fullWidth
                  variant="outlined"
                  inputProps={{ 'data-testid': 'headline' }}
                  type="text"
                  name="title"
                  id="title"
                  disabled={flushSrt}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  onClick={e => (e.target as HTMLInputElement).select()}
                  error={title.trim() === ''}
                  label="Headline"
                />
              </div>

              <div className="mt2">
                <TextField
                  fullWidth
                  multiline
                  variant="outlined"
                  inputProps={{ 'data-testid': 'description' }}
                  type="text"
                  name="description"
                  id="description"
                  disabled={flushSrt}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  onClick={e => (e.target as HTMLInputElement).select()}
                  label="Description"
                />
              </div>
            </React.Fragment>
          )}

          {adFormat === 'enhanced_outstream' && !srtContent && (
            <button
              type="submit"
              className="btn btn-secondary mt2"
              onClick={handleGenerateCaptions}
              disabled={enhancementProcessing}
            >
              Generate Captions
            </button>
          )}

          {adFormat === 'instream_video' && (
            <button
              type="submit"
              className="btn btn-secondary mt2"
              onClick={handleBurnCaptions}
              disabled={enhancementProcessing}
            >
              Generate Burn in Captions
            </button>
          )}

          {adFormat === 'ctv' && (
            <button
              type="submit"
              className="btn btn-secondary mt2"
              onClick={handleGenerateQrCode}
              disabled={enhancementProcessing}
            >
              Generate QR Code
            </button>
          )}

          {adFormat === 'enhanced_outstream' && srtContent && (
            <FormControlLabel
              control={
                <GreenSwitch
                  checked={flushSrt}
                  onChange={e => setFlushSrt(e.target.checked)}
                  name="flush_captions"
                />
              }
              label="Enable Captions"
            />
          )}

          {adFormat === 'enhanced_outstream' && srtContent && (
            <div className="mt2">
              <TextField
                fullWidth
                multiline
                variant="outlined"
                type="text"
                name="srt"
                id="srt"
                value={srtContent}
                disabled
                label="Captions"
                rows={srtContent.split(/\r\n|\r|\n/).length}
              />
            </div>
          )}

          {adFormat === 'enhanced_display' && (
            <div className="mt2">
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ 'data-testid': 'media_url' }}
                type="text"
                name="media_url"
                id="media_url"
                value={mediaUrl}
                onChange={e => setMediaUrl(e.target.value)}
                label="Click Tracker"
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default DynamicAdEnhancementForm;
