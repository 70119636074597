import axios, { AxiosRequestConfig } from 'axios';

const noop = () => {};

const http = {
  get: (url: string, config?: AxiosRequestConfig | undefined) =>
    axios.get(url, config),
  post: (url: string, data?: any, config?: AxiosRequestConfig | undefined) => {
    setupCsrf();
    return axios.post(url, data, config);
  },
  delete: (url: string, config?: AxiosRequestConfig | undefined) => {
    setupCsrf();
    return axios.delete(url, config);
  },
  put: (url: string, data?: any, config?: AxiosRequestConfig | undefined) => {
    setupCsrf();
    return axios.put(url, data, config);
  },
  patch: (url: string, data?: any, config?: AxiosRequestConfig | undefined) => {
    setupCsrf();
    return axios.patch(url, data, config);
  },
  uploadFile: (endpoint: string, file: File, onProgress = noop) => {
    setupCsrf();
    const formData = new FormData();
    formData.append('file', file);

    return axios.post(endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: onProgress
    });
  }
};

const setupCsrf = () => {
  // Copied from app_config.js
  // TODO: copy over humps (or not)
  const csrfElement = document.querySelector('meta[name=csrf-token]');
  // not there in tests
  if (csrfElement) {
    axios.defaults.headers.common['X-CSRF-Token'] = csrfElement.getAttribute(
      'content'
    );
  }
};

export default http;
