import { Type } from './EnhancementMessage';

export const instreamMessages = (status: string, failureReason: string) => {
  switch (status.toUpperCase()) {
    case 'CAPTIONED':
      return {
        type: Type.Notice,
        message: 'Congratulations your ad has been enhanced'
      };
    case 'IN_PROGRESS':
      return { type: Type.Alert, message: 'Ad enhancement in progress' };
    case 'NOT_ENHANCED':
      return {
        type: Type.Alert,
        message: 'This creative does not have any custom enhancements'
      };
    case 'FAILED':
      logFailureReason(status, failureReason);
      return { type: Type.Error, message: 'Ad enhancement failed' };
    case 'CANCELLED':
      return { type: Type.Error, message: 'Ad enhancement cancelled' };
    default:
      return {
        type: Type.Error,
        message: 'Ad enhancement failed: status message not recognized'
      };
  }
};

export const ctvMessages = (status: string, failureReason: string) => {
  switch (status.toUpperCase()) {
    case 'COMPLETE':
      return {
        type: Type.Notice,
        message: 'Congratulations your ad has been enhanced'
      };
    case 'PENDING':
      return { type: Type.Alert, message: 'Ad enhancement in progress' };
    case 'PROCESSING':
      return { type: Type.Alert, message: 'Ad enhancement in progress' };
    case 'NOT_ENHANCED':
      return {
        type: Type.Alert,
        message: 'This creative does not have any custom enhancements'
      };
    case 'FAILED':
      logFailureReason(status, failureReason);
      return { type: Type.Error, message: 'Ad enhancement failed' };
    case 'NOT_FOUND':
      return {
        type: Type.Error,
        message: 'Ad enhancement not found, please reach out to SFA product'
      };
    default:
      return {
        type: Type.Error,
        message: 'Ad enhancement failed: status message not recognized'
      };
  }
};

export const outstreamMessages = (
  status: string,
  revErrorReason: string,
  failureReason: string
) => {
  switch (status.toUpperCase()) {
    case 'SRT_GENERATED':
      return {
        type: Type.Notice,
        message: 'Congratulations your ad has been enhanced'
      };
    case 'SRT_BEING_PROCESSED':
      return { type: Type.Alert, message: 'Ad enhancement in progress' };
    case 'NOT_ENHANCED':
      return {
        type: Type.Alert,
        message: 'This creative does not have any custom enhancements'
      };
    case 'NO_MEDIA_URL_FOUND':
      return {
        type: Type.Error,
        message: 'Unable to generate captions, please reach out to SFA product'
      };
    case 'UNABLE_TO_GENERATE_SRT':
      return {
        type: Type.Error,
        message: 'Ad enhancement failed: No media file found'
      };
    case 'SRT_CANCELLED':
      return {
        type: Type.Error,
        message: outstreamRevErrorMessages(revErrorReason, failureReason)
      };
    case 'SRT_EMPTY':
      return {
        type: Type.Error,
        message: 'Ad enhancement failed: no sound or spoken words'
      };
    case 'BLOCKED':
      return {
        type: Type.Error,
        message: 'Ad enhancement failed: reach out to product for more details'
      };
    case 'FAILED':
      logFailureReason(status, failureReason);
      return { type: Type.Error, message: 'Ad enhancement failed' };
    default:
      return {
        type: Type.Error,
        message: 'Ad enhancement failed: status message not recognized'
      };
  }
};

export const alertMessages = (message: string) => {
  return { type: Type.Alert, message: message };
};

export const errorMessages = (message: string) => {
  return { type: Type.Error, message: message };
};

const outstreamRevErrorMessages = (status: string, failureReason: string) => {
  switch (status.toUpperCase()) {
    case 'DIFFICULT_CONTENT_POOR_QUALITY':
      return 'Ad enhancement failed: poor audio quality';
    case 'NO_AUDIO':
      return 'Ad enhancement failed: no audio';
    case 'NO_ENGLISH_AUDIO':
      return 'Ad enhancement failed: no English audio';
    case 'MUSIC_ONLY':
      return 'Ad enhancement failed: music only';
    default:
      logFailureReason(status, failureReason);
      return 'Ad enhancement failed: reach out to product for more details';
  }
};

const logFailureReason = (status: string, failureReason: string) => {
  console.error(
    'Enhancement status: ' + status + '. Failure reason: ' + failureReason
  );
};
