import React from 'react';
import { useCsrfToken } from '../hooks';

const FormCsrfInput = () => {
  const token = useCsrfToken();

  return (
    <input
      type="hidden"
      name="authenticity_token"
      value={token}
      readOnly={true}
    />
  );
};

export default FormCsrfInput;
