import React, { useState, Fragment } from 'react';
//@ts-ignore
import http from '../http';
import { TextField } from '@material-ui/core';

type Props = {
  payload:
    | { adm: string; action: string }
    | { vast_tag: string; action: string };
};

const GeneratorExport = ({ payload }: Props) => {
  const [showExportFields, setShowExportFields] = useState(false);
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [shareUrl, setShareUrl] = useState('');

  const submitCreative = async () => {
    try {
      const response = await http.post('/generator_creatives', {
        creative: {
          ...payload,
          name
        }
      });
      setShareUrl(response.data.share_url);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        setErrorMessage(e.response.data.message);
      } else {
        setErrorMessage('An error occurred');
      }
    }
  };

  return (
    <section>
      <hr className="ad-banner-separator" />
      {!showExportFields && (
        <button
          className="btn btn-secondary"
          onClick={() => setShowExportFields(true)}
        >
          Export this ad to NAG
        </button>
      )}
      {showExportFields && (
        <Fragment>
          {errorMessage && (
            <div className="container-fluid">
              <div className={`alert fade in alert-danger`}>{errorMessage}</div>
            </div>
          )}
          {shareUrl && (
            <div className="container-fluid">
              <div className={`alert fade in alert-success`}>
                <a href={shareUrl} target="_blank">
                  {shareUrl}
                </a>
              </div>
            </div>
          )}
          <div className="flex align-items-center">
            <TextField
              name="name_input"
              inputProps={{ 'data-testid': 'name_input' }}
              value={name}
              onChange={e => {
                setName(e.target.value);
              }}
              helperText="Name your ad and click save"
            />
            <button
              className="btn btn-primary ml2 align-self-start"
              type="submit"
              disabled={name.length === 0}
              onClick={submitCreative}
            >
              Save
            </button>
          </div>
        </Fragment>
      )}
    </section>
  );
};

export default GeneratorExport;
