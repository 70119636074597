export const truncate = (str: string, length: number = 30): string => {
  if (str.length <= length) {
    return str;
  }

  return `${str.substring(0, length)}...`;
};

export const encodeForUrl = (str: string) => {
  // some of our creative keys have . in them, need to do the extra
  // replace to make sure that it doesn't break the url, i also cannot just
  // encode it because the browser automatically decodes it to a .
  return encodeURIComponent(str).replace(/\./gi, '<dot>');
};

export const deepCopy = <T extends object>(x: T): T => {
  return JSON.parse(JSON.stringify(x));
};
