import React from 'react';
import {
  ColumnModel,
  CompareOperators,
  ColumnSortDirection,
  ColumnDataType
} from 'tubular-common';
import CreativesTable from './CreativesTable';
import { truncate } from '../utilities';

const NumericOperators = [
  { Value: CompareOperators.EQUALS, Title: 'Equals' },
  { Value: CompareOperators.GT, Title: '>' },
  { Value: CompareOperators.LT, Title: '<' }
];

const StringOperators = [
  { Value: CompareOperators.EQUALS, Title: 'Equals' },
  { Value: CompareOperators.CONTAINS, Title: 'Contains' },
  { Value: CompareOperators.STARTS_WITH, Title: 'Starts With' }
];

const StrictStringOperators = [
  { Value: CompareOperators.EQUALS, Title: 'Equals' }
];

ColumnModel.getOperators = (column: ColumnModel) => {
  if (column.Name === 'ad_format') {
    return StrictStringOperators;
  } else if (column.DataType === ColumnDataType.STRING) {
    return StringOperators;
  }
  return NumericOperators;
};

const columns = [
  new ColumnModel('lookup_key', {
    Label: 'Creative ID',
    Filterable: true,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('sources__display_name', {
    Label: 'DSP',
    Filterable: true,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('advertiser', {
    Label: 'Advertiser',
    Filterable: true,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('ad_format', {
    Label: 'Ad Format',
    Filterable: false,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('dynamic_ad_enhancements__created_at', {
    Label: 'Created',
    Filterable: false,
    Sortable: true,
    DataType: ColumnDataType.DATE_TIME_UTC
  }),
  new ColumnModel('dynamic_ad_enhancements__updated_at', {
    Label: 'Updated',
    Filterable: false,
    Sortable: true,
    SortDirection: ColumnSortDirection.DESCENDING,
    DataType: ColumnDataType.DATE_TIME_UTC
  }),
  new ColumnModel('dynamic_ad_enhancements__id', { Visible: false }),
  new ColumnModel('tubular_id', { Visible: false })
];

const TableCellContent = (column: ColumnModel, row: any) => {
  if (column.Name == 'lookup_key') {
    return (
      <a
        href={`dynamic_ad_enhancements/${row.dynamic_ad_enhancements__id}/edit`}
        target="_blank"
      >
        {truncate(row[column.Name], 20)}
      </a>
    );
  } else if (
    [
      'dynamic_ad_enhancements__created_at',
      'dynamic_ad_enhancements__updated_at'
    ].includes(column.Name)
  ) {
    return (
      <div key={column.Name}>{new Date(row[column.Name]).toLocaleString()}</div>
    );
  }
  return <div key={column.Name}>{row[column.Name]}</div>;
};

const DynamicAdEnhancementsTable = () => {
  const url = '/tubular/v1/dynamic_ad_enhancements';
  return (
    <CreativesTable
      columns={columns}
      url={url}
      tableCellContent={TableCellContent}
    />
  );
};

export default DynamicAdEnhancementsTable;
