import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Metric'
  },

  // default props for different component types
  props: {
    MuiTooltip: {
      placement: 'top'
    }
  },

  palette: {
    secondary: {
      main: '#1796DD'
    }
  },

  // component-level style overrides
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        fontSize: '16px',
        border: '1px solid #C7CFD7'
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: '16px',
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.38)',
        paddingLeft: '3px'
      }
    },
    MuiInput: {
      root: {
        fontSize: '16px',
        fontWeight: 400,
        border: 'none',
        paddingLeft: '3px'
      },
      underline: {
        '&:before': {
          borderBottomColor: 'rgba(0, 0, 0, 0.12)',
          borderBottomWidth: '1px'
        }
      }
    },
    MuiFormControl: {
      root: {
        borderBottomColor: 'rgba(0, 0, 0, 0.12)',
        borderBottomWidth: '1px',
        minWidth: '120px'
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '14px'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
        fontWeight: 400,
        backgroundColor: '#000',
        '&.tooltip--right': {
          '&:before': {
            right: '100%',
            left: 'initial',
            top: 'calc(50% - 6px)',
            borderRight: '6px solid #000',
            borderTop: '6px solid transparent',
            borderBottom: '6px solid transparent'
          }
        },
        '&.tooltip--left': {
          '&:before': {
            left: '100%',
            right: 'initial',
            top: 'calc(50% - 6px)',
            borderLeft: '6px solid #000',
            borderTop: '6px solid transparent',
            borderBottom: '6px solid transparent'
          }
        },
        '&.tooltip--bottom': {
          '&:before': {
            top: 'initial',
            left: 'calc(50% - 6px)',
            bottom: '100%',
            borderLeft: '6px solid transparent',
            borderRight: '6px solid transparent',
            borderBottom: '6px solid #000',
            borderTop: 'initial'
          }
        },
        '&:before': {
          /* Doubly-quoted empty string here beacuse we need MUI's
           * style parser to read this "JS style rule" and have it
           * spit out the empty string, otherwise the pseudo-element
           * doesn't make it into the DOM and none of the rules get
           * applied */
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          position: 'absolute',
          borderLeft: '6px solid transparent',
          borderRight: '6px solid transparent',
          borderTop: '6px solid #000',
          left: 'calc(50% - 6px)',
          top: '100%'
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '16px'
      }
    },
    MuiTable: {
      root: {
        fontSize: '16px'
      }
    },
    MuiTableCell: {
      head: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#37404a'
      },
      root: {
        paddingTop: 5,
        paddingBottom: 5
      },
      body: {
        fontSize: '16px',
        color: '#596777'
      }
    },
    MuiTableRow: {
      root: { '&:nth-child(even)': { backgroundColor: '#EFF2F5' } }
    }
  }
});

export default theme;
