import { useState, useEffect } from 'react';

export const useCsrfToken = () => {
  const getToken = () => {
    const metaTag = document.querySelector('meta[name=csrf-token]');
    const token = metaTag && metaTag.getAttribute('content');
    return token || '';
  };

  const [csrfToken, setCsrfToken] = useState(getToken());

  useEffect(() => {
    const foundToken = getToken();

    if (foundToken !== csrfToken) {
      setCsrfToken(getToken());
    }
  });

  return csrfToken;
};
