import React from 'react';
import {
  ColumnModel,
  ColumnSortDirection,
  ColumnDataType
} from 'tubular-common';
import CreativesTable, { CreativeColumnOperators } from './CreativesTable';
import { truncate, encodeForUrl } from '../utilities';

ColumnModel.getOperators = CreativeColumnOperators;

const columns = [
  new ColumnModel('creative_id', {
    Label: 'Creative Id',
    Filterable: true,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('adomain', {
    Label: 'Adomain',
    Filterable: true,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('headline', {
    Label: 'Title',
    Filterable: false,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('description', {
    Label: 'Description',
    Filterable: false,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('brand', {
    Label: 'Brand',
    Filterable: false,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('sources__display_name', {
    Label: 'DSP',
    Filterable: false,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('bidders__name', {
    Label: 'Bidder',
    Filterable: true,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('rendered_impressions', {
    Label: 'Impressions',
    Sortable: true,
    // Default sort is by rendered impressions
    SortDirection: ColumnSortDirection.DESCENDING,
    Filterable: true,
    DataType: ColumnDataType.NUMERIC
  }),
  new ColumnModel('spend', {
    Label: 'Spend',
    Sortable: true,
    Filterable: true,
    DataType: ColumnDataType.NUMERIC
  }),
  new ColumnModel('ctr', {
    Label: 'CTR',
    Sortable: true,
    Filterable: true,
    DataType: ColumnDataType.NUMERIC
  }),
  new ColumnModel('source_id', { Visible: false }),
  new ColumnModel('seat_key', { Visible: false }),
  new ColumnModel('tubular_id', { Visible: false }),
  new ColumnModel('live', { Visible: false })
];

const TableCellContent = (column: ColumnModel, row: any) => {
  return column.Name === 'creative_id' ? (
    <a
      href={`programmatic_clickouts/${encodeForUrl(
        row.creative_id
      )}/${encodeForUrl(row.source_id)}/${encodeForUrl(row.seat_key)}`}
      target="_blank"
    >
      {truncate(row[column.Name], 20)}
    </a>
  ) : (
    <div key={column.Name}>{row[column.Name]}</div>
  );
};

const ProgrammaticClickoutsTable = () => {
  const url = '/tubular/v1/programmatic_clickouts/' + window.location.search;

  return (
    <CreativesTable
      columns={columns}
      url={url}
      tableCellContent={TableCellContent}
    />
  );
};

export default ProgrammaticClickoutsTable;
