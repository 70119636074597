import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select
} from '@material-ui/core';

import theme from '../../materialTheme';
import OpportunityLineItemsTable from './OpportunityLineItemsTable';
import CSVModal from './CSVModal';

import { Creative, DSPCreativesContext } from './line_item_types';

type Props = {
  brands: Array<Brand>;
  opportunities: Array<Opportunity>;
};

type Brand = {
  account_id: string;
  account_name: string;
};

type Opportunity = {
  name: string;
  account_id: string;
  opportunity_id: string;
};

const initialBrandId = (brands: Brand[]) => {
  const param = new URL(window.location.href).searchParams.get('brand_id');
  const brand = brands.find(b => b.account_id === param);
  return brand ? brand.account_id : '';
};

const initialOpportunityId = (opportunities: Opportunity[]) => {
  const param = new URL(window.location.href).searchParams.get(
    'opportunity_id'
  );
  const opportunity = opportunities.find(b => b.opportunity_id === param);
  return opportunity ? opportunity.opportunity_id : '';
};

const OpportunityLineItems = (props: Props) => {
  const [brandId, setBrandId] = useState<string>(initialBrandId(props.brands));
  const [creatives, setCreatives] = useState<Array<Creative>>([]);

  const [opportunities, setOpportunities] = useState<Array<Opportunity>>([]);
  const [opportunityId, setOpportunityId] = useState<string>(
    initialOpportunityId(props.opportunities)
  );

  const handleBrandChange = (e: React.ChangeEvent<{ value: string }>) => {
    setBrandId(e.target.value);
    setOpportunityId('');
  };

  const handleOpportunityChange = (e: React.ChangeEvent<{ value: string }>) => {
    setOpportunityId(e.target.value);
    setCreatives([]);
  };

  // filter available opportunities to the selected brand
  useEffect(
    function filterOpportunityList() {
      setOpportunities(
        props.opportunities.filter(o => o.account_id === brandId)
      );
    },
    [brandId]
  );

  // if there's only 1 opportunity in the select list, auto select it
  useEffect(
    function autoSelectOpportunity() {
      const opp = opportunities[0];
      if (opp && opportunities.length == 1) {
        setOpportunityId(opp.opportunity_id);
      }
    },
    [opportunities]
  );

  // sync brand/opportunity choices to the URL so they can be copied/shared
  useEffect(
    function syncWindowUrl() {
      let url = new URL(window.location.href);
      if (brandId) {
        url.searchParams.set('brand_id', brandId);
      } else {
        url.searchParams.delete('brand_id');
      }
      if (opportunityId) {
        url.searchParams.set('opportunity_id', opportunityId);
      } else {
        url.searchParams.delete('opportunity_id');
      }

      window.history.replaceState('', '', url.toString());
    },
    [brandId, opportunityId]
  );

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <DSPCreativesContext.Provider value={{ creatives, setCreatives }}>
          <FormControl>
            <InputLabel id="brand-label">Brand</InputLabel>
            <Select
              labelId="brand-label"
              value={brandId}
              onChange={handleBrandChange}
              className="brand-select"
            >
              {props.brands.map((b: Brand) => (
                <MenuItem key={b.account_id} value={b.account_id}>
                  {b.account_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            disabled={opportunities.length === 0}
            style={{ marginLeft: '10px' }}
          >
            <InputLabel id="opportunity-label">Opportunity</InputLabel>
            <Select
              labelId="opportunity-label"
              value={opportunityId}
              onChange={handleOpportunityChange}
              className="opportunity-select"
            >
              {opportunities.map(o => (
                <MenuItem key={o.opportunity_id} value={o.opportunity_id}>
                  {o.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ marginLeft: '15px', marginBottom: '10px' }}>
            <p>
              You are viewing all Line Items associated with the selected
              Opportunity. To map Line Items to DSP Creatives, upload a new
              creative file:
            </p>
            <CSVModal />
          </FormControl>
          <OpportunityLineItemsTable opportunityId={opportunityId} />
        </DSPCreativesContext.Provider>
      </MuiThemeProvider>
    </>
  );
};

export default OpportunityLineItems;
