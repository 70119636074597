import React from 'react';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ColumnModel, CompareOperators, ColumnDataType } from 'tubular-common';
import { useCsrfToken } from '../hooks';

type Props = {
  columns: ColumnModel[];
  url: string;
  tableCellContent: (column: ColumnModel, row: any) => JSX.Element;
};

const NumericOperators = [
  { Value: CompareOperators.EQUALS, Title: 'Equals' },
  { Value: CompareOperators.GT, Title: '>' },
  { Value: CompareOperators.LT, Title: '<' }
];

const StringOperators = [
  { Value: CompareOperators.EQUALS, Title: 'Equals' },
  { Value: CompareOperators.CONTAINS, Title: 'Contains' },
  { Value: CompareOperators.STARTS_WITH, Title: 'Starts With' }
];

export const CreativeColumnOperators = (column: ColumnModel) => {
  return column.DataType === ColumnDataType.STRING
    ? StringOperators
    : NumericOperators;
};

const CreativesTable = (props: Props) => {
  const toolbarOptions = new ToolbarOptions({
    itemsPerPage: 30,
    rowsPerPageOptions: [],
    printButton: false,
    searchText: false,
    topPager: false,
    exportButton: false
  });

  const theme = createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          fontSize: '16px',
          fontFamily: 'Metric, sans-serif'
        },
        head: {
          backgroundColor: '#f5f5f5',
          color: '#888'
        }
      },
      MuiToolbar: { regular: { display: 'none' } },
      MuiTablePagination: { toolbar: { display: 'flex' } }
    }
  });

  const BodyContent = (row: any) => {
    return (
      <TableRow hover={true} key={`${row.tubular_id}`}>
        {props.columns
          .filter((col: ColumnModel) => col.Visible)
          .map((column: ColumnModel) => (
            <TableCell key={`${row.tubular_id}-${column.Name}`}>
              {props.tableCellContent(column, row)}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  const token = useCsrfToken();
  const request = new Request(props.url, {
    headers: { 'X-CSRF-Token': token },
    method: 'POST'
  });

  return (
    <ThemeProvider theme={theme}>
      <DataGrid
        columns={props.columns}
        dataSource={request}
        gridName="Creatives"
        toolbarOptions={toolbarOptions}
        bodyRenderer={BodyContent}
      />
    </ThemeProvider>
  );
};

export default CreativesTable;
