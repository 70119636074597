import { RUBICON_DISPLAY_SOURCE_ID } from './DynamicAdEnhancementForm';

const rubiconKeys = (lookupKey: string) => {
  const arr = lookupKey.split(':');

  if (arr[0] && arr[1]) {
    return { prefix: `${arr[0]}:`, creativeKey: arr[1] };
  } else {
    return { prefix: 'other', creativeKey: lookupKey };
  }
};

export const constructLookupKey = (
  creativeKeyPrefix: string,
  creativeKey: string,
  dealKey: string
) => {
  if (dealKey) {
    return dealKey;
  } else {
    const prefix = creativeKeyPrefix === 'other' ? '' : creativeKeyPrefix;
    return `${prefix}${creativeKey}`;
  }
};

export const parseLookupKey = (
  lookupKey: string,
  sourceId: string,
  spotxDeal: boolean
) => {
  let initalCreativeKey = '';
  let initialDealKey = '';
  let initialCreativeKeyPrefix = '';

  if (!!lookupKey) {
    if (spotxDeal) {
      initialDealKey = lookupKey;
    } else if (sourceId === RUBICON_DISPLAY_SOURCE_ID) {
      const keys = rubiconKeys(lookupKey);
      initalCreativeKey = keys.creativeKey;
      initialCreativeKeyPrefix = keys.prefix;
    } else {
      initalCreativeKey = lookupKey;
    }
  }

  return {
    initalCreativeKey,
    initialDealKey,
    initialCreativeKeyPrefix
  };
};
