import React, { useEffect, useState, useContext } from 'react';
import papa from 'papaparse';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Grid
} from '@material-ui/core';
import { Creative, DSPCreativesContext } from './line_item_types';
import toastr from 'toastr';

const CSVModal = () => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [uploadedCreatives, setUploadedCreatives] = useState<Array<Creative>>(
    []
  );
  const [manualCreatives, setManualCreatives] = useState<Array<Creative>>([]);
  const [creativeId, setCreativeId] = useState<string>('');
  const [creativeName, setCreativeName] = useState<string>('');
  const { setCreatives } = useContext(DSPCreativesContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = () => {
    setCreatives([...uploadedCreatives, ...manualCreatives]);
    handleClose();
    toastr.success('Data saved.');
  };

  const handleTextInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setter: (value: string) => void
  ) => {
    setter(e.target.value);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files && e.target.files[0]);
  };

  const exportFile = () => {
    var csv = papa.unparse({
      fields: ['CreativeName', 'CreativeId'],
      data: []
    });

    let file = new File([csv], 'template.csv', {
      type: 'text/csv;charset=utf-8'
    });
    var csvURL = window.URL.createObjectURL(file);

    var tmpLink = document.createElement('a');
    tmpLink.href = csvURL;
    tmpLink.setAttribute('download', 'template.csv');
    tmpLink.click();
  };

  const handleAddManualCreative = () => {
    setManualCreatives([
      ...manualCreatives,
      { creative_key: creativeId, creative_name: creativeName }
    ]);
    setCreativeId('');
    setCreativeName('');
  };

  useEffect(
    function extractCreativeListFromCsv() {
      if (!file) {
        return;
      }
      papa.parse(file, {
        header: true,
        complete: (results, _file) => {
          setUploadedCreatives(
            results.data.map(d => ({
              creative_key: d.CreativeID || d.CreativeId,
              creative_name: d.CreativeName,
              opportunity_line_item_id: ''
            }))
          );

          if (!results.data[0].CreativeId && !results.data[0].CreativeID) {
            toastr.error(
              "There's no column named 'CreativeId' please correct your file and try again."
            );
            setOpen(false);
          }
          if (!results.data[0].CreativeName) {
            toastr.error(
              "There's no column named 'CreativeName' please correct your file and try again."
            );
            setOpen(false);
          }
        }
      });
    },
    [file]
  );

  return (
    <div>
      <Grid container>
        <Grid item xs={2}>
          <Button variant="outlined" color="primary" onClick={handleOpen}>
            Upload Items
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button variant="outlined" color="primary" onClick={exportFile}>
            Download template
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Upload Items</DialogTitle>
        <DialogContent style={{ padding: '0 24px' }}>
          <Grid container>
            <DialogContentText>
              You can select a .csv file that includes two columns &mdash;
              “CreativeID” and “CreativeName”.
            </DialogContentText>
            <Button
              variant="contained"
              component="label"
              className="btn btn-primary"
            >
              <input
                accept=".csv"
                id="file-upload"
                type="file"
                onChange={handleFileChange}
              />
            </Button>
          </Grid>
          <Divider style={{ margin: '20px 0' }} />
          <DialogContentText>
            Or manually insert your information.
          </DialogContentText>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              {manualCreatives.length > 0 && (
                <p>{manualCreatives.length} Creatives manually added.</p>
              )}
            </Grid>
            <Grid item xs>
              <TextField
                id="creative-id"
                value={creativeId}
                label="CreativeID"
                variant="outlined"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleTextInputChange(e, setCreativeId)
                }
              />
            </Grid>
            <Grid item xs>
              <TextField
                id="creative-name"
                value={creativeName}
                label="CreativeName"
                variant="outlined"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleTextInputChange(e, setCreativeName)
                }
              />
            </Grid>
            <Grid item xs>
              <Button
                variant="contained"
                className="btn btn-primary"
                onClick={handleAddManualCreative}
                disabled={creativeId.length === 0 || creativeName.length === 0}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleUpload}
            disabled={!file && manualCreatives.length === 0}
            className="btn btn-primary"
          >
            Done
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CSVModal;
