import React, { useContext, useEffect, useState } from 'react';
import http from '../../http';
import { deepCopy } from '../../utilities';
import {
  Button,
  Checkbox,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';

import { LineItem, DSPCreativesContext } from './line_item_types';
import CreativeModal from './CreativeModal';

type Props = {
  opportunityId: string;
};

type Sort = {
  column: 'start_date' | 'end_date' | 'name';
  descending: boolean;
};

const defaultSort = () => ({ column: 'start_date', descending: false } as Sort);

const OpportunityLineItemsTable = (props: Props) => {
  const [lineItems, setLineItems] = useState<Array<LineItem>>([]);
  const [sort, setSort] = useState<Sort>(defaultSort);
  const { creatives } = useContext(DSPCreativesContext);
  const [selectedLineItems, setSelectedLineItems] = useState<Set<number>>(
    new Set([])
  );
  const [showCreativeModal, setShowCreativeModal] = useState<Boolean>(false);

  const selectAll = () => {
    let newSelectedLineItems = new Set(selectedLineItems);
    if (selectedLineItems.size === lineItems.length) {
      newSelectedLineItems.clear();
    } else {
      lineItems.map((_item, i) => newSelectedLineItems.add(i));
    }
    setSelectedLineItems(newSelectedLineItems);
  };

  const handleSelectedLineItemChange = (index: number) => {
    let newSelectedLineItems = new Set(selectedLineItems);
    if (selectedLineItems.has(index)) {
      newSelectedLineItems.delete(index);
    } else {
      newSelectedLineItems.add(index);
    }
    setSelectedLineItems(newSelectedLineItems);
  };

  const disabledCreativeKeys = () => {
    if (selectedLineItems.size == 1) {
      return new Set(
        lineItems[
          Array.from(selectedLineItems)[0]
        ].opportunity_line_item_creatives.map(c => c.creative_key)
      );
    }
    return new Set([]);
  };

  useEffect(
    function fetchLineItems() {
      if (props.opportunityId) {
        http
          .get(`api/v1/line_items?opportunity_id=${props.opportunityId}`)
          .then(response => {
            setLineItems(response.data);
          });
      } else {
        setLineItems([]);
        setSort(defaultSort());
      }
    },
    [props.opportunityId]
  );

  useEffect(
    function sortLineItems() {
      if (lineItems.length == 0) {
        return;
      }
      const compare = (a: LineItem, b: LineItem) => {
        // Use toUpperCase() to ignore character casing
        const valueA = a[sort.column].toUpperCase();
        const valueB = b[sort.column].toUpperCase();

        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      };

      let newLineItems = deepCopy(lineItems);
      newLineItems.sort(compare);
      if (sort.descending) {
        newLineItems.reverse();
      }
      setLineItems(newLineItems);
    },
    [sort]
  );

  const creativeCount = (lineItem: LineItem) => {
    let count = lineItem.opportunity_line_item_creatives.length;
    return count;
  };

  /*
    newCreativeKeys: corresponds to creatives from the uploaded line items
    existingCreativeKeys: corresponds to creatives that were already attached to the line item
  */
  const updateLineItemCreatives = (
    newCreativeKeys: Set<string>,
    existingCreativeKeys: Set<string>,
    selectedLineItems: Set<number>
  ) => {
    let newLineItems = deepCopy(lineItems);
    selectedLineItems.forEach(li => {
      let newLineItem = deepCopy(newLineItems[li]) as LineItem;
      // Initialize new creative list filtering out any keys that were de-selected
      // from the existing creatives on the line item
      let newCreatives = newLineItem.opportunity_line_item_creatives.filter(c =>
        existingCreativeKeys.has(c.creative_key)
      );

      const keysToNotDuplicate = new Set(newCreatives.map(c => c.creative_key));

      // Add in creatives are are in the newKeys but NOT already added
      // This filter is important for bulk mappings
      newCreatives = newCreatives.concat(
        creatives.filter(
          c =>
            newCreativeKeys.has(c.creative_key) &&
            !keysToNotDuplicate.has(c.creative_key)
        )
      );

      newLineItem.opportunity_line_item_creatives = newCreatives;
      newLineItems[li] = newLineItem;

      setLineItems(newLineItems);
      setSelectedLineItems(selectedLineItems);
    });
  };

  return (
    <>
      <Grid container justify="center" spacing={1}>
        <Grid item xs={3}>
          <Button
            variant="outlined"
            color="primary"
            disabled={selectedLineItems.size < 2}
            id="bulk-selection-button"
            onClick={() => setShowCreativeModal(selectedLineItems.size > 0)}
            style={{
              position: 'fixed',
              right: '1%',
              top: '10%'
            }}
          >
            Bulk selection
          </Button>
        </Grid>
      </Grid>
      <Paper>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSort({
                    column: 'name',
                    descending: !sort.descending
                  });
                }}
              >
                <TableSortLabel
                  active={sort.column == 'name'}
                  direction={sort.descending ? 'desc' : 'asc'}
                ></TableSortLabel>
                Line Item
              </TableCell>
              <TableCell
                onClick={() => {
                  setSort({
                    column: 'start_date',
                    descending: !sort.descending
                  });
                }}
                style={{ cursor: 'pointer' }}
              >
                <TableSortLabel
                  active={sort.column == 'start_date'}
                  direction={sort.descending ? 'desc' : 'asc'}
                ></TableSortLabel>
                Start Date
              </TableCell>
              <TableCell
                onClick={() => {
                  setSort({
                    column: 'end_date',
                    descending: !sort.descending
                  });
                }}
                style={{ cursor: 'pointer' }}
              >
                <TableSortLabel
                  active={sort.column == 'end_date'}
                  direction={sort.descending ? 'desc' : 'asc'}
                ></TableSortLabel>
                End Date
              </TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Brief Description</TableCell>
              <TableCell>Budget</TableCell>
              <TableCell>DSP Creatives</TableCell>
              <TableCell>
                <Link onClick={selectAll}>Select All</Link>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems.map((row, idx) => (
              <TableRow key={row.opportunity_line_item_id}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.start_date}</TableCell>
                <TableCell>{row.end_date}</TableCell>
                <TableCell>{row.mapped_product_type}</TableCell>
                <TableCell>{row.brief_description}</TableCell>
                <TableCell>$ {row.budget}</TableCell>
                <TableCell>
                  <Link
                    onClick={() => {
                      setSelectedLineItems(new Set([idx]));
                      setShowCreativeModal(true);
                    }}
                  >
                    {creativeCount(row) > 0
                      ? `${creativeCount(row)} Creatives Selected`
                      : 'Make Selection'}
                  </Link>
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={selectedLineItems.has(idx)}
                    id={`checkbox-${row.opportunity_line_item_id}`}
                    onChange={() => handleSelectedLineItemChange(idx)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {showCreativeModal && (
        <CreativeModal
          lineItems={lineItems}
          updateExistingCreatives={(
            newCreativeKeys: Set<string>,
            existingCreativeKeys: Set<string>
          ) =>
            updateLineItemCreatives(
              newCreativeKeys,
              existingCreativeKeys,
              selectedLineItems
            )
          }
          disabledCreativeKeys={
            selectedLineItems.size > 1 ? new Set() : disabledCreativeKeys()
          }
          handleClose={() => setShowCreativeModal(false)}
          selectedLineItems={selectedLineItems}
        />
      )}
    </>
  );
};

export default OpportunityLineItemsTable;
