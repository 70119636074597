import { createContext } from 'react';

export type Creative = {
  creative_name: string;
  creative_key: string;
};

export type LineItem = {
  mapped_product_type: string;
  name: string;
  opportunity_line_item_id: string;
  start_date: string;
  end_date: string;
  brief_description: string;
  opportunity_line_item_creatives: Creative[];
  budget: number;
};

export const defaultDSPCreativeContext: {
  creatives: Creative[];
  setCreatives: (n: Creative[]) => void;
} = { creatives: [], setCreatives: _c => {} };

export const DSPCreativesContext = createContext(defaultDSPCreativeContext);
