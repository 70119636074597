import React from 'react';
import {
  ColumnModel,
  ColumnSortDirection,
  ColumnDataType
} from 'tubular-common';
import CreativesTable, { CreativeColumnOperators } from './CreativesTable';
import { truncate, encodeForUrl } from '../utilities';
import ClosedCaptionIcon from '@material-ui/icons/ClosedCaption';
import QrCodeIcon from '@material-ui/icons/PictureInPictureAlt';

ColumnModel.getOperators = CreativeColumnOperators;

const columns = [
  new ColumnModel('creative_id', {
    Label: 'Creative Id',
    Filterable: true,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('adomain', {
    Label: 'Adomain',
    Filterable: true,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('sources__display_name', {
    Label: 'DSP',
    Filterable: false,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('video_starts', {
    Label: 'Video Starts',
    Sortable: true,
    // Default sort is by video starts
    SortDirection: ColumnSortDirection.DESCENDING,
    Filterable: true,
    DataType: ColumnDataType.NUMERIC
  }),
  new ColumnModel('spend', {
    Label: 'Spend ($)',
    Sortable: true,
    Filterable: true,
    DataType: ColumnDataType.NUMERIC
  }),
  new ColumnModel('video_start_rate', {
    Label: 'VSR',
    Sortable: true,
    Filterable: true,
    DataType: ColumnDataType.NUMERIC
  }),
  new ColumnModel('video_completion_rate', {
    Label: 'VCR',
    Sortable: true,
    Filterable: true,
    DataType: ColumnDataType.NUMERIC
  }),
  new ColumnModel('ctr', {
    Label: 'CTR',
    Sortable: true,
    Filterable: true,
    DataType: ColumnDataType.NUMERIC
  }),
  new ColumnModel('ad_type', {
    Label: 'Ad Type',
    Sortable: false,
    Filterable: false,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('source_id', { Visible: false }),
  new ColumnModel('seat_key', { Visible: false }),
  new ColumnModel('tubular_id', { Visible: false }),
  new ColumnModel('live', { Visible: false }),
  new ColumnModel('caption', { Visible: false }),
  new ColumnModel('qr_code', { Visible: false })
];

const TableCellContent = (column: ColumnModel, row: any) => {
  if (column.Name !== 'creative_id') {
    return <div key={column.Name}>{row[column.Name]}</div>;
  }

  const ad_type_path =
    row.ad_type === 'Instream' || row.ad_type === 'CTV'
      ? 'instream_videos'
      : 'programmatic_outstream_videos';

  return (
    <div>
      <i
        className={`ss-record live-indicator ${
          row.live ? 'text-live' : 'text-inactive'
        }`}
      ></i>
      {row.caption && (
        <ClosedCaptionIcon
          fontSize="small"
          style={{ color: '#00BAA8', verticalAlign: 'middle', marginRight: 5 }}
        />
      )}
      {row.qr_code && (
        <QrCodeIcon
          fontSize="small"
          style={{ color: '#00BAA8', verticalAlign: 'middle', marginRight: 5 }}
        />
      )}
      <a
        href={`${ad_type_path}/${encodeForUrl(row.creative_id)}/${encodeForUrl(
          row.source_id
        )}/${encodeForUrl(row.seat_key)}?ad_type=${encodeForUrl(row.ad_type)}`}
        target="_blank"
      >
        {truncate(row[column.Name], 20)}
      </a>
    </div>
  );
};

const VideoTable = () => {
  const url = `/tubular/v1/videos${window.location.search}`;

  return (
    <CreativesTable
      columns={columns}
      url={url}
      tableCellContent={TableCellContent}
    />
  );
};

export default VideoTable;
