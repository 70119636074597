import React, { useState, useContext } from 'react';
import http from '../../http';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  Paper,
  TableBody,
  TableCell,
  Table,
  TableHead,
  TableRow
} from '@material-ui/core';

import { LineItem, DSPCreativesContext, Creative } from './line_item_types';

type Props = {
  lineItems: Array<LineItem>;
  updateExistingCreatives: (
    newCreativeKeys: Set<string>,
    existingCreativeKeys: Set<string>
  ) => void;
  handleClose: () => void;
  disabledCreativeKeys: Set<string>;
  selectedLineItems: Set<number>;
};

const CreativeModal = (props: Props) => {
  const { creatives } = useContext(DSPCreativesContext);
  // Set of creative keys that were uploaded that have been selected
  const [newCreativeKeys, setNewCreativeKeys] = useState<Set<string>>(
    new Set([])
  );

  const newExistingCreativeKeys = new Set<string>([]);
  props.lineItems.forEach(li => {
    li.opportunity_line_item_creatives.map(c =>
      newExistingCreativeKeys.add(c.creative_key)
    );
  });
  const [existingCreativeKeys, setExistingCreativeKeys] = useState<Set<string>>(
    newExistingCreativeKeys
  );

  // Variable created to filter the new creatives uploaded
  const [creativeFilter, setCreativeFilter] = useState<string>('');

  const persistSelection = () => {
    if (!props.lineItems && !props.selectedLineItems) {
      return Promise.reject();
    }

    return Promise.all(
      Array.from(props.selectedLineItems).map(opp => {
        let newCreatives = props.lineItems[
          opp
        ].opportunity_line_item_creatives.filter(c =>
          existingCreativeKeys.has(c.creative_key)
        );
        const keysToNotDuplicate = new Set(
          newCreatives.map(c => c.creative_key)
        );
        // Add in creatives are are in the newKeys but NOT already added
        creatives
          .filter(c => newCreativeKeys.has(c.creative_key))
          .filter(c => !keysToNotDuplicate.has(c.creative_key))
          .map(c => {
            newCreatives.push(c);
          });
        return http.put(
          `api/v1/line_item_creatives/${props.lineItems[opp].opportunity_line_item_id}`,
          {
            creatives: newCreatives
          }
        );
      })
    );
  };

  const handleClose = () => {
    props.handleClose();
  };

  const handleConfirm = () => {
    persistSelection().then(() => {
      props.updateExistingCreatives(newCreativeKeys, existingCreativeKeys);

      props.handleClose();
    });
  };

  type CreativesTableProps = {
    creatives: Creative[];
    selectedKeys: Set<string>;
    toggleSelection: (ckey: string) => void;
    disableSet: Set<string>; // ckeys that are disabled for this table
  };

  const CreativesTable = ({
    creatives,
    selectedKeys,
    toggleSelection,
    disableSet
  }: CreativesTableProps) => {
    return (
      <Paper>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Select</TableCell>
              <TableCell>Creative Name</TableCell>
              <TableCell>Creative ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {creatives.map(row => (
              <TableRow key={row.creative_key}>
                <TableCell>
                  <Checkbox
                    checked={selectedKeys.has(row.creative_key)}
                    disabled={disableSet.has(row.creative_key)}
                    id={`checkbox-${row.creative_key}`}
                    onChange={() => toggleSelection(row.creative_key)}
                  />
                </TableCell>
                <TableCell>{row.creative_name}</TableCell>
                <TableCell>{row.creative_key}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  };

  const UploadedCreatives = () => {
    if (creatives.length > 0) {
      return (
        <>
          <DialogContentText>
            Please select the DSP Creative IDs that belong to this Salesforce
            Line Item
          </DialogContentText>
          <CreativesTable
            creatives={lineItemCreatives()}
            selectedKeys={newCreativeKeys}
            disableSet={props.disabledCreativeKeys}
            toggleSelection={(ckey: string) => {
              let keys = new Set([...newCreativeKeys.values()]);
              if (keys.has(ckey)) {
                keys.delete(ckey);
              } else {
                keys.add(ckey);
              }
              setNewCreativeKeys(keys);
            }}
          ></CreativesTable>
        </>
      );
    } else {
      return (
        <DialogContentText>
          Upload a CSV to select new creatives
        </DialogContentText>
      );
    }
  };

  const ExistingCreatives = () => {
    const seen: Set<string> = new Set();
    const existingCreatives: Creative[] = [];
    // need to deduplicate creatives that might be mapped to more than one
    // line item and selected for the same bulk selection
    props.selectedLineItems.forEach(li => {
      props.lineItems[li].opportunity_line_item_creatives.forEach(c => {
        if (!seen.has(c.creative_key)) {
          seen.add(c.creative_key);
          existingCreatives.push(c);
        }
      });
    });

    if (existingCreatives.length > 0) {
      return (
        <>
          <DialogContentText>Existing Mapped Creatives</DialogContentText>
          <CreativesTable
            creatives={existingCreatives}
            selectedKeys={existingCreativeKeys}
            disableSet={new Set([])}
            toggleSelection={(ckey: string) => {
              let keys = new Set([...existingCreativeKeys.values()]);
              if (keys.has(ckey)) {
                keys.delete(ckey);
              } else {
                keys.add(ckey);
              }
              setExistingCreativeKeys(keys);
            }}
          ></CreativesTable>
        </>
      );
    }
    return null;
  };

  const lineItemCreatives = () => {
    if (creativeFilter.length === 0) {
      return creatives;
    }
    return creatives.filter(creative =>
      creative.creative_name
        .toLowerCase()
        .includes(creativeFilter.toLowerCase())
    );
  };

  return (
    <>
      {props.lineItems && (
        <Dialog open={true} maxWidth="lg">
          <DialogTitle>
            {props.selectedLineItems.size} Line Items Selected
          </DialogTitle>
          <DialogContent>
            <div className="filter-input_wrapper">
              {creatives.length > 0 ? (
                <Input
                  type="text"
                  onChange={e => setCreativeFilter(e.target.value)}
                  value={creativeFilter}
                  key="creativeFilterInput"
                  placeholder="Search Creative Name"
                ></Input>
              ) : (
                ''
              )}
            </div>
            <div className="creative-table_wrapper">
              <UploadedCreatives />
            </div>
            <div className="creative-table_wrapper">
              <ExistingCreatives />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleConfirm}
              variant="contained"
              component="label"
              className="btn btn-primary"
            >
              Save
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default CreativeModal;
