export const VENDORS = [
  {
    name: 'Double Verify',
    removeFromTag: '&btreg=',
    addToTag: '&bttag={{adTag}}&btattb=data-str-rendered&btreg={{RenderTime}}',
    addToPosition: 'src_end'
  },
  {
    name: 'IAS',
    removeFromTag: null,
    addToTag: '?ias_adpath=%5bdata-str-rendered%3d%27{{RenderTime}}%27%5d',
    addToPosition: 'src_end'
  },
  {
    name: 'MOAT',
    removeFromTag: null,
    addToTag: null,
    addToPosition: 'src_end'
  }
];

export const DSPS = [
  {
    name: 'AppNexus',
    dblVerify_buyerInstructions:
      'Your tag has been succesfully updated with Sharethrough macros and converted to an impression pixel. You may now copy and paste the pixel into the AppNexus impression tracker field.',
    IAS_buyerInstructions:
      'Your tag has been succesfully updated with Sharethrough macros and converted to an impression pixel. You may now copy and paste the pixel into the AppNexus impression tracker field.',
    MOAT_buyerInstructions:
      'Traffic this Moat tag as is into the AppNexus javascript tracker field'
  },
  {
    name: 'BrightRoll',
    dblVerify_buyerInstructions:
      'Your tag has been succesfully updated with Sharethrough macros. You may now copy and paste the tag into the BrightRoll javascript tracker field.',
    IAS_buyerInstructions:
      'Your tag has been succesfully updated with Sharethrough macros. You may now copy and paste the tag into the BrightRoll javascript tracker field.',
    MOAT_buyerInstructions:
      'Traffic this Moat tag as is into the BrightRoll javascript tracker field'
  },
  {
    name: 'DBM',
    dblVerify_buyerInstructions:
      'Your tag has been succesfully updated with Sharethrough macros and converted to an impression pixel. You may now copy and paste the pixel into the DBM impression tracker field.',
    IAS_buyerInstructions:
      'Your tag has been succesfully updated with Sharethrough macros and converted to an impression pixel. You may now copy and paste the pixel into the DBM impression tracker field.',
    MOAT_buyerInstructions:
      'Your tag has been succesfully converted to an impression pixel. You may now copy and paste the pixel into the DBM impression tracker field.'
  },
  {
    name: 'SAMDesk Managed Service',
    dblVerify_buyerInstructions:
      'Traffic modified DV tag as is into SAMDesk UI',
    IAS_buyerInstructions: 'Traffic modified IAS tag as is into SAMDesk UI',
    MOAT_buyerInstructions: 'Traffic Moat tag as is into SAMDesk UI'
  },
  {
    name: 'The Trade Desk',
    dblVerify_buyerInstructions:
      "Your tag has been succesfully updated with Sharethrough macros and converted to an impression pixel. You may now copy and paste the pixel into The Trade Desk's impression tracker field.",
    IAS_buyerInstructions:
      "Your tag has been succesfully updated with Sharethrough macros and converted to an impression pixel. You may now copy and paste the pixel into The Trade Desk's impression tracker field.",
    MOAT_buyerInstructions:
      "Your tag has been succesfully converted to an impression pixel. You may now copy and paste the pixel into The Trade Desk's impression tracker field."
  }
];
