// TODO: cache this response
const getSessionInfo = () => {
  // TODO: implement server endpoint once users exist
  return Promise.resolve({ user: { guest: true } });
  // return http.get('/api/internal/session_info').then(({ data }) => data);
};

const setSessionInformation = () => {
  return getSessionInfo().then(function (info) {
    // TODO: implement getSessionInfo fully
    // const user = info.user;

    // if (!info.user.guest) {
    //   // TODO: import and set up mixpanel without `window`
    //   window.mixpanel.identify(info.user.id);

    //   window.mixpanel.people.set({
    //     $email: user.email,
    //     $first_name: user.firstName,
    //     $last_name: user.lastName
    //   });
    // }

    return info;
  });
};

const track = (event: string, attrs?: object) => {
  const customProperties = attrs || {};
  return setSessionInformation()
    .then(_ => {
      // TODO: Fully implement getUserInfo
      // customProperties.ipAddress = info.ipAddress;

      // if (info.partner) {
      //   customProperties.partner = info.partner.name;
      //   customProperties.partnerType = info.partner.partnerType;
      // }
      window.mixpanel.track(event, customProperties);
    })
    .catch(() => {}); // TODO: log error
};

const trackBooleanSelection = (val: boolean, name: string, attrs: object) => {
  var state = val === true ? 'Enabled' : 'Disabled';
  // ex: Sports Category Filter Enabled
  return track(name + ' ' + state, attrs).catch(function () {
    return;
  });
};

const trackFilterSelection = (
  filter: { name: string; selected: boolean },
  filterType: object,
  attrs: object
) => {
  //ex: Sports Category Filter
  var eventName = filter.name + ' ' + filterType + ' Filter';
  return trackBooleanSelection(filter.selected, eventName, attrs);
};

export default {
  track,
  trackBooleanSelection,
  trackFilterSelection
};
