import React from 'react';
import {
  ColumnModel,
  ColumnSortDirection,
  ColumnDataType
} from 'tubular-common';
import CreativesTable, { CreativeColumnOperators } from './CreativesTable';
import { truncate, encodeForUrl } from '../utilities';

ColumnModel.getOperators = CreativeColumnOperators;

const columns = [
  new ColumnModel('creative_id', {
    Label: 'Creative Id',
    Filterable: true,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('adomain', {
    Label: 'Adomain',
    Filterable: true,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('sources__display_name', {
    Label: 'DSP',
    Filterable: false,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('bidders__name', {
    Label: 'Bidder',
    Filterable: true,
    DataType: ColumnDataType.STRING
  }),
  new ColumnModel('rendered_impressions', {
    Label: 'Impressions',
    Sortable: true,
    // Default sort is by rendered impressions
    SortDirection: ColumnSortDirection.DESCENDING,
    Filterable: true,
    DataType: ColumnDataType.NUMERIC
  }),
  new ColumnModel('spend', {
    Label: 'Spend ($)',
    Sortable: true,
    Filterable: true,
    DataType: ColumnDataType.NUMERIC
  }),
  new ColumnModel('ctr', {
    Label: 'CTR',
    Sortable: true,
    Filterable: true,
    DataType: ColumnDataType.NUMERIC
  }),
  new ColumnModel('banner_render_rate', {
    Label: 'Render %',
    Sortable: true,
    Filterable: true,
    DataType: ColumnDataType.NUMERIC
  }),
  new ColumnModel('source_id', { Visible: false }),
  new ColumnModel('seat', { Visible: false }),
  new ColumnModel('tubular_id', { Visible: false }),
  new ColumnModel('live', { Visible: false })
];

const TableCellContent = (column: ColumnModel, row: any) => {
  return column.Name === 'creative_id' ? (
    <div>
      <i
        className={`ss-record live-indicator ${
          row.live ? 'text-live' : 'text-inactive'
        }`}
      ></i>
      <a
        href={`programmatic_banners/${encodeForUrl(
          row.creative_id
        )}/${encodeForUrl(row.source_id)}/${encodeForUrl(row.seat)}`}
        target="_blank"
      >
        {truncate(row[column.Name], 20)}
      </a>
    </div>
  ) : (
    <div key={column.Name}>{row[column.Name]}</div>
  );
};

const ProgrammaticBannersTable = () => {
  const url = '/tubular/v1/programmatic_banners/' + window.location.search;

  return (
    <CreativesTable
      columns={columns}
      url={url}
      tableCellContent={TableCellContent}
    />
  );
};

export default ProgrammaticBannersTable;
