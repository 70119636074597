import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { VENDORS, DSPS } from '../components/constants';

type Props = {
  token: string;
};

const JsTracker = (props: Props): JSX.Element => {
  const tempTagEl = useRef(null);

  const [originalTag, setOriginalTag] = useState('');
  const [vendor, setVendor] = useState(Object || null);
  const [dsp, setDsp] = useState(Object || null);

  const [convertedPixel, setConvertedPixel] = useState('');
  const [conversionReady, setConversionReady] = useState(false);
  const [errors, setErrors] = useState('');

  const [successMessage, setSuccessMessage] = useState({
    heading: '',
    subHeading: '',
    instructions: ''
  });

  const vendors = VENDORS;
  const dsps = DSPS;

  useEffect(() => {
    if (Object.keys(dsp).length > 0 && Object.keys(vendor).length > 0) {
      setConversionReady(true);
      if (originalTag) {
        convertPixel();
      }
    }
  }, [dsp, vendor]);

  const triggerError = (error: string) => {
    setOriginalTag('');
    setConvertedPixel('');
    setErrors(error);
  };

  const sanitizeTag = (tag: string) => {
    var src = tag;
    var type = 'string';

    if (!tag) {
      return triggerError('Tag Required');
    }

    // @ts-ignore
    let script = tempTagEl.current.children;

    if (script.length > 1) {
      return triggerError('Multiple <scripts> not allowed');
    }
    if (script[0]) {
      src = script[0].attributes.src ? script[0].attributes.src.nodeValue : '';
      type = script[0].nodeName.toLowerCase();
      if (type !== 'script') {
        return triggerError('A valid <script> is required');
      }
    } else {
      return triggerError('A valid <script> is required');
    }
    return { src: src, type: type };
  };

  const shouldConvertToImpressionPixel = () => {
    var dspName = dsp.name.toLowerCase();
    switch (vendor.name.toLowerCase()) {
      case 'moat':
        if (dspName === 'the trade desk' || dspName === 'dbm') return true;
        break;
      case 'ias':
        if (
          dspName === 'the trade desk' ||
          dspName === 'appnexus' ||
          dspName === 'dbm'
        )
          return true;
        break;
      case 'double verify':
        if (
          dspName === 'the trade desk' ||
          dspName === 'appnexus' ||
          dspName === 'dbm'
        )
          return true;
        break;
    }
    return false;
  };

  const convertPixel = async () => {
    setErrors('');

    const tag = sanitizeTag(originalTag);
    if (tag) {
      if (vendor.addToTag) {
        tag.src += vendor.addToTag;
      }

      if (vendor.removeFromTag) {
        tag.src = tag.src.replace(vendor.removeFromTag, '');
      }

      if (tag.type === 'script') {
        tag.src =
          '<script src="' + tag.src + '" type="text/javascript"></script>';
      }

      if (shouldConvertToImpressionPixel()) {
        var csrf_token = props.token;
        await axios
          .post(
            '/jstracker',
            {
              url: tag.src
            },
            {
              headers: {
                'X-CSRF-Token': csrf_token
              }
            }
          )
          .then(response => {
            tag.src =
              'https://b.sharethrough.com/js_tracker?key=' +
              response.data.pixel_key;
            setConvertedPixel(tag.src);
            outputInstructions();
          })
          .catch(_error => {
            triggerError("Please disable your browser's AdBlocker.");
          });
      } else {
        setConvertedPixel(tag.src);
        outputInstructions();
      }
    }
  };

  const outputInstructions = () => {
    const heading = 'Success';
    const subHeading = "Here's the new tag for " + vendor.name;
    let instructions = '';

    const vendorName = vendor.name.toLowerCase();
    if (vendorName === 'double verify') {
      instructions = dsp.dblVerify_buyerInstructions;
    }
    if (vendorName === 'ias') {
      instructions = dsp.IAS_buyerInstructions;
    }
    if (vendorName === 'moat') {
      instructions = dsp.MOAT_buyerInstructions;
    }
    setSuccessMessage({ heading, subHeading, instructions });
  };

  return (
    <div className="content-wrapper">
      <div className="container content">
        {errors && (
          <div role="alert" className="alert alert-danger">
            {errors}
          </div>
        )}

        <div
          className="temp"
          style={{ display: 'none' }}
          ref={tempTagEl}
          dangerouslySetInnerHTML={{ __html: originalTag }}
        />

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="vendor-select">
                Select Your Viewability Vendor
              </label>
              <select
                id="vendor-select"
                defaultValue=""
                className="form-control"
                onChange={event => {
                  const vendor = vendors.find(
                    v => v.name === event.target.value
                  );
                  setVendor(vendor);
                }}
              >
                <option disabled={true} value="">
                  Please select one
                </option>
                {vendors.map((vendor, index) => (
                  <option key={index} value={vendor.name}>
                    {vendor.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="dsp-select">Which DSP is this tag for?</label>
              <select
                id="dsp-select"
                defaultValue=""
                className="form-control"
                onChange={event => {
                  const dsp = dsps.find(d => d.name === event.target.value);
                  setDsp(dsp);
                }}
              >
                <option disabled={true} value="">
                  Please select one
                </option>
                {dsps.map((dsp, index) => (
                  <option key={index} value={dsp.name}>
                    {dsp.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="tag-input">
                Paste the tag you'd like to convert
              </label>
              <textarea
                id="tag-input"
                className="form-control"
                rows={7}
                onChange={event => setOriginalTag(event.target.value)}
                placeholder="&lt;script&gt; goes here..."
              ></textarea>
            </div>

            <button
              className="btn btn-primary"
              disabled={!conversionReady}
              onClick={() => convertPixel()}
            >
              Get Tracker URL
            </button>
          </div>

          {convertedPixel && (
            <div className="col-md-6 message-pane animated zoomIn">
              <h3>
                <img src="//s3-us-west-2.amazonaws.com/s.cdpn.io/39451/ic-links.png" />
                {successMessage.heading}
              </h3>
              <h4>{successMessage.subHeading}</h4>
              <p>{successMessage.instructions}</p>
              <textarea
                className="form-control generated-pixel"
                id="pixel"
                rows={7}
                readOnly={true}
                value={convertedPixel}
              ></textarea>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JsTracker;
