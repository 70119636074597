window.addEventListener('message', event => {
  if (event.data.key !== 'BannerMetadata') {
    return;
  }
  const successToast = document.querySelector(
    '.clickable-info_clickable'
  ) as HTMLElement;
  const failToast = document.querySelector(
    '.clickable-info_not-clickable'
  ) as HTMLElement;

  if (event.data.payload.clickable) {
    successToast.style.display = 'inline-block';
  } else {
    failToast.style.display = 'inline-block';
  }
});
