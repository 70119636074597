import React from 'react';
import './EnhancementMessage.scss';

export type Props = {
  type: Type;
  message: string;
};

export enum Type {
  Notice = 'Notice',
  Alert = 'Alert',
  Error = 'Error'
}

const EnhancementMessage = (props: Props) => {
  const messageType = () => {
    switch (props.type) {
      case Type.Notice:
        return 'alert-success';

      case Type.Alert:
        return 'alert-soft-warning';

      case Type.Error:
        return 'alert-danger';

      default:
        return 'alert-danger';
    }
  };

  return (
    <div
      data-testid="enhancement-status"
      className={`alert custom-alert ${messageType()}`}
    >
      <span data-testid="enhancement-status-msg">{props.message}</span>
    </div>
  );
};

export default EnhancementMessage;
