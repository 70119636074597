import React from 'react';
import EnhancementMessage from './enhancement_status/EnhancementMessage';
import {
  instreamMessages,
  ctvMessages,
  outstreamMessages,
  errorMessages
} from './enhancement_status/messages';

type Props = {
  adType: string;
  responseStatus: number;
  isResponseSuccessful: boolean;
  responseData: any;
};

enum AdTypes {
  INSTREAM = 'INSTREAM',
  CTV = 'CTV',
  OUTSTREAM = 'OUTSTREAM'
}

const EnhancementStatus = (props: Props) => {
  const handleResponseError = (responseStatus: number, responseData: any) => {
    let error = errorMessages(
      'Ad enhancement failed: internal problem, please reach out to SFA product'
    );

    switch (responseStatus) {
      case 400:
        console.error(
          'Enhancement status: ' +
            responseStatus +
            ' error: ' +
            JSON.stringify(responseData)
        );
        error = errorMessages(
          'Ad enhancement failed: invalid arguments, please reach out to SFA product'
        );
        return <EnhancementMessage type={error.type} message={error.message} />;
      case 404:
        error = errorMessages(
          'Ad enhancement not found, please reach out to SFA product'
        );
        return <EnhancementMessage type={error.type} message={error.message} />;
      default:
        console.error(
          'status: ' +
            responseStatus +
            ' error: ' +
            JSON.stringify(responseData)
        );
        return <EnhancementMessage type={error.type} message={error.message} />;
    }
  };

  // This func. handles some edge cases regarding the Enhancement API.
  // Also, handles basic cases for missing expected attr from response.
  const handleResponse = (adType: string, responseData: any) => {
    // for outstream and instream, Enhanc. API returns an array of object.
    // for qrcode it returns an object.
    const res = Array.isArray(responseData) ? responseData[0] : responseData;

    let status = '';
    let revErrorReason = '';
    const failureReason = res.failureReason ? res.failureReason : 'not given';
    const defaultStatusError = 'FAILED';
    const defaultError =
      'Enhancement error: status not found or null for ' + adType + ' ad';

    switch (adType.toUpperCase()) {
      // For INSTREAM the attribute is called revStatus
      // check doc. https://sharethrough.atlassian.net/wiki/spaces/SFA/pages/26416906251/Ad+enhancements+-+API+statuses
      case AdTypes.INSTREAM:
        if (res.hasOwnProperty('revStatus') && res.revStatus) {
          status = res.revStatus;
        } else {
          status = defaultStatusError;
          console.error(defaultError);
        }
        break;

      case AdTypes.CTV:
        if (res.hasOwnProperty('status') && res.status) {
          status = res.status;
          // Fix for qrcode enhancement: API returns 200 and empty data when Enhancement status not found (404).
        } else if (Object.keys(res).length == 0) {
          status = 'NOT_FOUND';
        } else {
          status = defaultStatusError;
          console.error(defaultError);
        }
        break;

      case AdTypes.OUTSTREAM:
        if (res.hasOwnProperty('status') && res.status) {
          status = res.status;
          revErrorReason = res.revErrorReason;
        } else {
          status = defaultStatusError;
          console.error(defaultError);
        }
        break;
    }

    return [status, revErrorReason, failureReason];
  };

  const isEnhancementStatusAd = (adType: string) => {
    switch (adType.toUpperCase()) {
      case AdTypes.INSTREAM:
        return true;
      case AdTypes.CTV:
        return true;
      case AdTypes.OUTSTREAM:
        return true;
      default:
        return false;
    }
  };

  const enhancementStatusMgs = (adType: string, responseData: any) => {
    const [status, revErrorReason, failureReason] = handleResponse(
      adType,
      responseData
    );

    switch (adType.toUpperCase()) {
      case AdTypes.INSTREAM:
        const instream = instreamMessages(status, failureReason);
        return (
          <EnhancementMessage type={instream.type} message={instream.message} />
        );

      case AdTypes.CTV:
        const ctv = ctvMessages(status, failureReason);
        return <EnhancementMessage type={ctv.type} message={ctv.message} />;

      case AdTypes.OUTSTREAM:
        const outstream = outstreamMessages(
          status,
          revErrorReason,
          failureReason
        );
        return (
          <EnhancementMessage
            type={outstream.type}
            message={outstream.message}
          />
        );

      default:
        const error = errorMessages(
          'Ad enhancement failed: ad type not recognized'
        );
        return <EnhancementMessage type={error.type} message={error.message} />;
    }
  };

  if (isEnhancementStatusAd(props.adType)) {
    return (
      <>
        {props.isResponseSuccessful
          ? enhancementStatusMgs(props.adType, props.responseData)
          : handleResponseError(props.responseStatus, props.responseData)}
      </>
    );
  } else {
    return null;
  }
};

export default EnhancementStatus;
