import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField
} from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import './BeaconCustomizer.scss';

const BeaconCustomizer = () => {
  const [billingEvent, setBillingEvent] = useState('visimp');
  const [originalBeacon, setOriginalBeacon] = useState('');
  const [customBeacon, setCustomBeacon] = useState<string | null>(null);

  useEffect(
    function updateCustomBeacon() {
      if (!billingEvent || !originalBeacon) {
        return;
      }
      let newBeacon;
      try {
        const existingParams = originalBeacon.match(/(?<=\?)(.*)/);
        newBeacon = originalBeacon;
        if (originalBeacon.match(/strcustom=/)) {
          newBeacon = originalBeacon.replace(
            /(?<=strcustom=)(.*?)(?=&|$)/,
            billingEvent
          );
        } else if (existingParams) {
          if (existingParams[0] !== '') {
            newBeacon += '&';
          }
          newBeacon += `strcustom=${billingEvent}`;
        } else {
          newBeacon += `?strcustom=${billingEvent}`;
        }
      } catch (e) {
        newBeacon = null;
      }

      setCustomBeacon(newBeacon);
    },
    [billingEvent, originalBeacon]
  );

  const copyText = () => {
    return customBeacon ? customBeacon : '';
  };

  return (
    <div className="beacon-customizer mt2">
      <FormControl>
        <InputLabel htmlFor="billing-select">Billing Event</InputLabel>
        <Select
          classes={{ root: 'beacon-customizer_billing-event' }}
          variant="filled"
          inputProps={{
            'data-testid': 'select-input',
            id: 'billing-select'
          }}
          value={billingEvent}
          onChange={event => setBillingEvent(event.target.value as string)}
          native
        >
          <option value={'visimp'}>Visible Impression</option>
          <option value={'clk'}>Click</option>
          <option value={'03'}>3 Sec View</option>
          <option value={'10'}>10 Sec View</option>
          <option value={'15'}>15 Sec View</option>
          <option value={'30'}>30 Sec View</option>
        </Select>
      </FormControl>
      Enter your original 1x1 tag URL here:
      <TextField
        multiline
        id="beacon-customizer_original-beacon"
        variant="filled"
        label="Original Beacon"
        classes={{ root: 'beacon-customizer_text-field' }}
        InputProps={{ value: originalBeacon }}
        onChange={event => setOriginalBeacon(event.target.value)}
      />
      <TextField
        multiline
        id="beacon-customizer_customized-beacon"
        variant="filled"
        classes={{ root: 'beacon-customizer_text-field' }}
        label="Customized Beacon"
        InputProps={{ value: copyText() }}
      />
      <CopyToClipboard text={copyText()}>
        <Button
          color="primary"
          variant="contained"
          classes={{ root: 'beacon-customizer_copy-button' }}
        >
          Copy Custom Beacon
        </Button>
      </CopyToClipboard>
    </div>
  );
};

export default BeaconCustomizer;
